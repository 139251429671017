import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  logoSofisaSimplificado,
  setaParaBaixo,
  sofisaDireto,
} from '../../assets';
import {
  HeaderSofisaComponent,
  LevelComponent,
  LogoOpenFinanceComponent,
} from '../../components';
import { InstituicaoFinanceira } from '../../models/open-finance';
import { RedirectItpOpenFinance } from '../../services';
import { OpenFinanceStore } from '../../store';
import styles from './redirect-itp.module.css';

export interface RedirectItpScreenProps {
  operacaoFoiCancelada: boolean;
  instituicaoDestino: InstituicaoFinanceira;
  redirectLink: string;
}

export function RedirectItpScreen() {
  const [isPessoaFisica, setIsPessoaFisica] = useState<boolean>();
  const { state } = useLocation() as { state: RedirectItpScreenProps };

  const destino = state.instituicaoDestino;
  const timeoutRedirect =
    parseInt(`${process.env.REACT_APP_TIMEOUT_REDIRECT}` ?? '') || 6000;

  useEffect(() => {
    OpenFinanceStore.getDadosOperacao().then((dadosOperacao) => {
      setIsPessoaFisica(dadosOperacao!.tipoPessoa === 'PF');
    });

    setTimeout(() => {
      if (!state.operacaoFoiCancelada) {
        RedirectItpOpenFinance.apagaDadosOperacao();
      }
      window.location.href = state.redirectLink;
    }, timeoutRedirect);
  }, []);

  return (
    <div className={styles.rootContainer}>
      <HeaderSofisaComponent />
      <div className={styles.contentContainer}>
        <div className={styles.levelContainer}>
          <LevelComponent level={'Redirecionamento'} />
        </div>
        <div>
          <LogoOpenFinanceComponent />
        </div>
        <div className={styles.containerBancos}>
          <div className={styles.banco}>
            {isPessoaFisica ? (
              <>
                <img src={sofisaDireto} />
                <span>Sofisa Direto</span>
              </>
            ) : (
              <>
                <img src={logoSofisaSimplificado} />
                <span>Banco Sofisa</span>
              </>
            )}
          </div>
          <div className={styles.transitionCard}>
            <img src={setaParaBaixo} />
            <span className={styles.texto}>
              Você está sendo redirecionado para a instituição de origem.
            </span>
          </div>
          <div className={styles.banco}>
            <img src={destino.logoUrl} />
            <span>{destino.nome}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
