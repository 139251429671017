import { ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  fechar,
  infoImage,
  linhaAzul,
  linhaVermelha,
  proibido,
  logoOpenFinance,
  redirecionamento,
  questionMark,
  levelIcon
} from '../../assets';
import {
  BotaoComponent,
  EstiloBotao,
  HeaderSofisaComponent,
  InputComponent,
  LevelComponent,
  LoadingComponent,
  EtapasComponent
} from '../../components';
import { Consentimento, Erro } from '../../models/open-finance';
import { ConsentimentoOpenFinance, OpenFinanceService } from '../../services';
import {
  HttpError,
  ResponseAutenticacao,
} from '../../services/open-finance/responses';
import { OpenFinanceStore } from '../../store';
import { RedirectItpScreenProps } from './redirect-itp.screen';
import styles from './validacao-token.module.css';
import redirectStyles from './consentimento-dados.module.css'

export interface ValidacaoTokenScreenProps {
  produtosSelecionados: string[];
}

interface InstrucoesToken {
  labelInputToken: string;
  instrucaoInputToken: ReactElement;
  avisoAtivacaoToken: ReactElement;
}

export function ValidacaoTokenScreen() {
  const navigate = useNavigate();
  const { state } = useLocation() as { state: ValidacaoTokenScreenProps };
  const [dadosOperacao, setDadosOperacao] = useState<ResponseAutenticacao>();
  const [jaCarregouDados, setJaCarregouDados] = useState<boolean>(false);
  const [serialNumber, setSerialNumber] = useState<string>();
  const [instrucoesToken, setInstrucoesToken] = useState<InstrucoesToken>();
  const [showBlockedTokenWarning, setShowBlockedTokenWarning] =
    useState<boolean>(false);
  const [jaSubmeteu, setJaSubmeteu] = useState<boolean>(false);
  const [token, setToken] = useState<string>();
  const [isConfirmandoOperacao, setIsConfirmandoOperacao] =
    useState<boolean>(false);
    const [operacao, setOperacao] = useState<string>("pagamento");

  useEffect(() => {
    OpenFinanceStore.getDadosOperacao().then((dadosOperacao) => {
      setDadosOperacao(dadosOperacao);
    });
  }, []);

  useEffect(() => {
    setJaCarregouDados(!!dadosOperacao);

    if (dadosOperacao != null) {
      if(Object.keys(dadosOperacao).includes("permissoes")) setOperacao("compartilhamento")
      if (dadosOperacao.tipoPessoa === 'PF') {
        setInstrucoesToken({
          labelInputToken: 'Token Direto',
          instrucaoInputToken: (
            <span>
              Para prosseguir e garantir sua segurança, insira o Token Direto.
            </span>
          ),
          avisoAtivacaoToken: (
            <span>
              <b>Como ativar o Token: </b> Acesse sua conta no app do Sofisa
              Direto e ative-o através do menu "Segurança {'>'} Dispositivos de
              Segurança"
            </span>
          ),
        });
      } else {
        obtemTokenPJ();
      }
    }
  }, [dadosOperacao]);

  useEffect(() => {
    setJaSubmeteu(false);
  }, [token]);

  const obtemTokenPJ = () =>{
    OpenFinanceService.validaTokenCliente(dadosOperacao!.cpf).then((response) => {
      setSerialNumber(response.serialNumber);
      setShowBlockedTokenWarning(!!!response.active);
      setInstrucoesToken({
        labelInputToken: 'Senha gerada no Token',
        instrucaoInputToken: (
          <span>
            Para prosseguir e garantir sua segurança, insira a senha gerada
            no <b>Token {response.serialNumber}</b>.
          </span>
        ),
        avisoAtivacaoToken: (
          <span>
            <b>Importante: </b> Caso não possua token para realizar essa
            operação você pode entrar em contato com seu gerente ou com o
            nosso suporte.
          </span>
        ),
      });
    });
  }

  const cancelaOperacao = () => {
    ConsentimentoOpenFinance.cancela().then((response: Erro | null) => {
      if (!response) {
        navigate('/');
        return;
      }

      navigate('/open-finance/redirect-itp', {
        state: {
          operacaoFoiCancelada: true,
          instituicaoDestino: response.instituicaoDestino,
          redirectLink: response.redirectLink,
        } as RedirectItpScreenProps,
      });
    });
  };

  function confirmaOperacao(): void {
    const isTokenPreenchido = !!token && token.trim() !== '';
    if (!isTokenPreenchido) {
      setJaSubmeteu(true);
      return;
    }
    setIsConfirmandoOperacao(true);
    ConsentimentoOpenFinance.consente(
      state.produtosSelecionados,
      token,
      serialNumber,
    ).then((response: Consentimento | HttpError) => {
      if (isConsentimentoResponse(response)) {
        navigate('/open-finance/redirect-itp', {
          state: {
            operacaoFoiCancelada: false,
            instituicaoDestino: response.instituicaoDestino,
            redirectLink: response.redirectLink,
          } as RedirectItpScreenProps,
        });
        return;
      }

      setToken(undefined);
      setIsConfirmandoOperacao(false);
      if(dadosOperacao && dadosOperacao!.tipoPessoa === 'PJ')  obtemTokenPJ();
      toast.error(
        'Não conseguimos confirmar a operação. Por favor, tente novamente.',
        {
          position: 'top-center',
          autoClose: 10000,
        },
      );
    });
  }

  const retornaResumoCompartilhamento = () => {
    navigate('/open-finance/consentimentoDados');
  };

  const isConsentimentoResponse = (
    response: Consentimento | HttpError,
  ): response is Consentimento => {
    return 'instituicaoDestino' in response;
  };

  if (!jaCarregouDados || !instrucoesToken) {
    return <></>;
  }

  if (isConfirmandoOperacao) {
    if(Object.keys(dadosOperacao!).includes("permissoes")) {
      return (
        <div className={styles.rootContainer}>
        <HeaderSofisaComponent />
          <div className={styles.contentContainer}>
            <EtapasComponent
              passos={['Confirmação', 'Resumo', 'Redirecionamento']}
              atual={2}
            />
          <div className={redirectStyles.containerConsentimento}>
            <div className={redirectStyles.containerCompartilhamento}>
              <img src={redirecionamento}/>
              <div>
                <span className={redirectStyles.tituloOperacao}>
                  Estamos lhe redirecionando para a instituição {dadosOperacao!.nomeInstituicao}.
                </span>
              </div>
              <div className={redirectStyles.containerRedirectFooter}>
                <div className={redirectStyles.redirectCompartilhamentoText}>
                  Este compartilhamento de dados será realizado através do 
                </div>
                <div>
                  <img src={logoOpenFinance}/>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      );
    }
    else{
      return (
        <LoadingComponent mensagem="Confirmando operação...">
          <HeaderSofisaComponent />
        </LoadingComponent>
      );

    }
  }

  if(Object.keys(dadosOperacao!).includes("permissoes")) 
  {
    return(
      <>
      <ToastContainer />
      <div className={styles.rootContainer}>
        <HeaderSofisaComponent />
        <div className={styles.contentContainer}>
          {showBlockedTokenWarning && (
            <div className={`${styles.containerTokenBloqueado}`}>
              <img src={linhaVermelha} className={styles.linhaAviso} />
              <div className={styles.containerMensagem}>
                <div className={styles.image}>
                  <img src={proibido} />
                </div>
                <div className={styles.mensagem}>
                  <div>Token bloqueado</div>
                  <div>
                    Quantidade máxima de tentativas excedidas. Favor contatar o
                    suporte.
                  </div>
                </div>
                <div
                  className={styles.botaoFechar}
                  onClick={() => cancelaOperacao()}
                  >
                  <img src={fechar} />
                </div>
              </div>
            </div>
          )}
          <div className={styles.instrucaoToken}>
            {instrucoesToken.instrucaoInputToken}
          </div>
            <div className={styles.containerToken}>
              <div className={styles.detalhesToken}>
                Para realizar essa operação, ative o Token Direto no seu celular, depois gere o código pelo atalho na tela inicial do aplicativo usando seu aparelho.
                <img src={questionMark}/>
              </div>
              <div className={styles.inputTokenContainer}>
              <InputComponent
                inlineLabel={instrucoesToken.labelInputToken}
                onChangeValor={setToken}
                jaSubmeteu={jaSubmeteu}
                validacao={{
                  obrigatorio: true,
                }}
              />
            </div>
            </div>
          <div className={styles.avisoAtivacaoToken}>
            {instrucoesToken.avisoAtivacaoToken}
          </div>
          <div className={styles.containerBotoes}>
            <BotaoComponent
              titulo="Voltar"
              estiloBotao={EstiloBotao.voltar}
              onClick={retornaResumoCompartilhamento}
              estaHabilitado={true}
              imagem={levelIcon}
            />
            <div className={styles.avancar}>
              <BotaoComponent
                titulo="Avançar"
                estiloBotao={EstiloBotao.avancar}
                onClick={confirmaOperacao}
                estaHabilitado={!showBlockedTokenWarning}
                />
            </div>
          </div>
        </div>
      </div>
    </>
  )
  }
  else
  {
    return (
      <>
      <ToastContainer />
      <div className={styles.rootContainer}>
        <HeaderSofisaComponent />
        <div className={styles.contentContainer}>
          <LevelComponent level={'Validação de token'} />
          {showBlockedTokenWarning && (
            <div className={`${styles.containerTokenBloqueado}`}>
              <img src={linhaVermelha} className={styles.linhaAviso} />
              <div className={styles.containerMensagem}>
                <div className={styles.image}>
                  <img src={proibido} />
                </div>
                <div className={styles.mensagem}>
                  <div>Token bloqueado</div>
                  <div>
                    Quantidade máxima de tentativas excedidas. Favor contatar o
                    suporte.
                  </div>
                </div>
                <div
                  className={styles.botaoFechar}
                  onClick={() => cancelaOperacao()}
                  >
                  <img src={fechar} />
                </div>
              </div>
            </div>
          )}
          <div className={styles.instrucaoToken}>
            {instrucoesToken.instrucaoInputToken}
          </div>
          <div className={styles.inputToken}>
            <InputComponent
              label={instrucoesToken.labelInputToken}
              onChangeValor={setToken}
              jaSubmeteu={jaSubmeteu}
              validacao={{
                obrigatorio: true,
              }}
            />
          </div>
          <div className={styles.avisoAtivacaoToken}>
            {instrucoesToken.avisoAtivacaoToken}
          </div>
          <div className={styles.containerAviso}>
            <img src={linhaAzul} className={styles.linhaAviso} />
            <div className={styles.containerMensagem}>
              <div className={styles.image}>
                <img src={infoImage} />
              </div>
              <div className={styles.mensagem}>
                Vamos te redirecionar de volta para finalizar seu {operacao}
              </div>
            </div>
          </div>
          <div className={styles.botoes}>
            <BotaoComponent
              titulo="Confirmar"
              estiloBotao={EstiloBotao.confirmacao}
              onClick={confirmaOperacao}
              estaHabilitado={!showBlockedTokenWarning}
              />
          </div>
        </div>
      </div>
    </>
    );
  }
}
