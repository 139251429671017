import { Route, Routes } from 'react-router-dom';

import './App.css';
import {
  AutorizacaoAbortadaScreen,
  AutorizacaoScreen,
  ClienteAutenticadoScreen,
  ConsentimentoDadosScreen,
  ConsentimentoPagamentoScreen,
  HomeScreen,
  RedirectItpScreen,
  ValidacaoTokenScreen,
} from './screens/';

function App() {
  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route
          path="/open-finance/autorizacao-abortada"
          element={<AutorizacaoAbortadaScreen />}
        />
        <Route
          path="/open-finance/autorizacao"
          element={<AutorizacaoScreen />}
        />
        <Route
          path="/open-finance/clienteAutenticado/:dados"
          element={<ClienteAutenticadoScreen />}
        />
        <Route
          path="/open-finance/consentimentoPagamento"
          element={<ConsentimentoPagamentoScreen />}
        />
        {<Route
          path="/open-finance/consentimentoDados"
          element={<ConsentimentoDadosScreen />}
        />}
        <Route
          path="/open-finance/redirect-itp"
          element={<RedirectItpScreen />}
        />
        <Route
          path="/open-finance/validacao-token"
          element={<ValidacaoTokenScreen />}
        />
      </Routes>
    </div>
  );
}

export default App;
