import styles from './etapas.module.css';

export interface EtapasProps {
  passos: string[];
  atual: number;
}

export default function EtapasComponent({ passos, atual }: EtapasProps) {
  return (
    <div className={styles.container}>
      {passos.map((p, i) => (
        <span key={`e-${i}`}>
          <div className={`${styles.etapa} ${atual === i ? styles.atual : ''}`}>
            <div className={styles.indice}>{i + 1}</div>
            <div className={styles.nome}>{p}</div>
          </div>
          {i < passos.length - 1 && <span className={styles.separador} />}
        </span>
      ))}
    </div>
  );
}
