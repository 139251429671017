import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { infoImage, linhaAzul, linhaVermelha, warning } from '../../assets';
import {
  BotaoComponent,
  EstiloBotao,
  HeaderSofisaComponent,
  LevelComponent,
  Modal,
} from '../../components';
import { ContaOrigem, DadosPagamento, Erro } from '../../models/open-finance';
import { ConsentimentoOpenFinance } from '../../services';
import { OpenFinanceStore } from '../../store';
import styles from './consentimento-pagamento.module.css';
import { RedirectItpScreenProps } from './redirect-itp.screen';
import { ValidacaoTokenScreenProps } from './validacao-token.screen';
import ExchangeComponent from '../../components/exchange.component';

export function ConsentimentoPagamentoScreen() {
  const navigate = useNavigate();
  const [dadosOperacao, setDadosOperacao] = useState<DadosPagamento>();
  const [contaOrigem, setContaOrigem] = useState<ContaOrigem>();
  const [jaCarregouDados, setJaCarregouDados] = useState<boolean>(false);
  const [recursosValidos, setRecursosValidos] = useState<boolean>(false);
  const [avisoCancelamento, setAvisoCancelamento] = useState<boolean>(false);
  const [contaOrigemDefinida, setContaOrigemDefinida] =
    useState<boolean>(false);
  const avisoAgendamento =
    'É necessário ter saldo suficiente para a transferência na data do agendamento. Caso contrário, o pagamento não será efetuado.';
  const avisoMultiplaAlcada = 
    'Identificamos que será necessária uma ou mais autorizações para a conclusão do pagamento.A transação só será efetivada após a autorização de todos os aprovadores.Os aprovadores têm até 13/07/2021 às 23:59 para autorizar a transação.Após essa data uma nova ordem de pagamento deverá ser feita.'
  const avisoTransferenciaInteligente =
    'Ao autorizar, você será redirecionado de forma segura para o ambiente que estava.'

  useEffect(() => {
    OpenFinanceStore.getDadosOperacao().then((dadosOperacao) => {
      setDadosOperacao(dadosOperacao as DadosPagamento);
    });
  }, []);

  useEffect(() => {
    setJaCarregouDados(!!dadosOperacao);

    if (dadosOperacao != undefined && dadosOperacao?.contasOrigem.length > 0)
    {
      if (dadosOperacao?.contasOrigem.length === 1)
      {
        setContaOrigem(dadosOperacao.contasOrigem[0]);
        setContaOrigemDefinida(true);
      } 
      // console.log(`Setando dados de conta origem: ${JSON.stringify(dadosOperacao.contasOrigem[0], null)}`);
      setRecursosValidos(true);
    }
    else
    {
      setRecursosValidos(false);
    }
  }, [dadosOperacao]);

  const confirmaOperacao = () => {
    navigate('/open-finance/validacao-token', {
      state: {
        produtosSelecionados: [contaOrigem!.id],
      } as ValidacaoTokenScreenProps,
    });
  };

  const confirmaContaOrigem = (): void => {
    if (contaOrigem != null) {
      setContaOrigemDefinida(true);
    }
  };

  const cancelaOperacao = () => {
    ConsentimentoOpenFinance.cancela().then((response: Erro | null) => {
      if (!response) {
        navigate('/');
        return;
      }

      navigate('/open-finance/redirect-itp', {
        state: {
          operacaoFoiCancelada: true,
          instituicaoDestino: response.instituicaoDestino,
          redirectLink: response.redirectLink,
        } as RedirectItpScreenProps,
      });
    });
  };

  const contaOrigemElement = (): ReactElement => {
    return (
      <div className={styles.cardOperacao}>
        <div>
          <span className={styles.tituloOperacao}>Selecione sua conta</span>
        </div>
        <div className={styles.selecaoContaOrigem}>
          {dadosOperacao!.contasOrigem.map((conta, index) => {
            const resourceConta = conta.resources.find((r) =>
              r.key.toLowerCase() === 'conta corrente');
            const resourceAgencia = conta.resources.find((r) =>
              r.key.toLowerCase() === 'agencia');
            return (
              <div>
                <div className={styles.inputContaOrigem}>
                  <input
                    type="radio"
                    name="conta-origem"
                    value={index}
                    onClick={() => setContaOrigem(conta)}
                  />
                </div>
                <div className={styles.dadosContaOrigem}>
                  <div>Conta Corrente</div>
                  <div>
                    agencia {resourceAgencia?.value} / conta{' '}
                    {resourceConta?.value}
                  </div>
                  {conta.saldo && conta.moedaSaldo && ( 
                    <div>
                      Saldo em conta:{' '}
                      {Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: conta.moedaSaldo,
                      }).format(conta.saldo)}
                    </div>
                    )
                  }
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const consentimentoElement = (): ReactElement => {
    const dados = dadosOperacao!;

    const valor = !dados.isTransferenciaInteligente ? Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: dados.moedaTransacao,
    }).formatToParts(+dados.valorTransacao) : undefined;
    const indexInteger = !dados.isTransferenciaInteligente ? valor!.findIndex((v) => v.type === 'integer') : undefined;
    const indexDecimal = !dados.isTransferenciaInteligente ? valor!.findIndex((v) => v.type === 'decimal') : undefined;
    const significante = !dados.isTransferenciaInteligente ? valor!
      .slice(indexInteger, indexDecimal)
      .map((v) => v.value)
      .join('') : undefined;
    const fracao = !dados.isTransferenciaInteligente ? valor?.find((v) => v.type === 'fraction')!.value : undefined;
    const moeda = !dados.isTransferenciaInteligente ? valor![0].value : undefined;
    let conta = !dados.isTransferenciaInteligente ? contaOrigem!.resources.find((r) => r.key.toLowerCase() === "conta corrente"): undefined;
    // console.log(`Buscando dados da conta origem para exibir a fonte do pagamento: ${JSON.stringify(dadosOperacao.contasOrigem[0], null)}`);

    if(recursosValidos === true)
    {
      return (
      <div className={styles.cardOperacao}>
        <div>
          <span className={styles.tituloOperacao}>
            {(!dados.isAgendamento && !dados.isTransferenciaInteligente) || (dados?.isAgendamento && dados.dadosAgendamento?.type === "SINGLE") && 'Resumo da transação'}
            {dados?.isAgendamento && dados.dadosAgendamento?.type !== "SINGLE" && 'Você está autorizando um agendamento recorrente'}
            {dados?.isTransferenciaInteligente && "Autorizar transferência inteligente"}
          </span>
          <div>
          {dados?.isTransferenciaInteligente && "As transferências apenas serão autorizadas para contas do mesmo titular."}
          </div>
        </div>
        {
          !dados.isTransferenciaInteligente && (
            <div>
              <div
                className={`${styles.containerTituloSecao} ${styles.containerValorPagamento}`}
              >
                <span className={styles.valorPagamento}>Valor a pagar</span>
              </div>
              <div>
                <span>
                  <span className={styles.moedaPagamento}>{moeda}</span>{' '}
                  <span className={styles.valorSignificante}>{significante}</span>
                  <span className={styles.valorFracionado}>,{fracao}</span>
                </span>
              </div>
            </div>
          )
        }
        {
          !dados.isTransferenciaInteligente && (
            <div>
              <div className={styles.containerTituloSecao}>
                <span>Dados do recebedor:</span>
              </div>
              <div>CPF: {dados.contaDestino!.cpfCnpj}</div>
              <div>Nome: {dados.contaDestino!.titular}</div>
            </div>
          )
        }
        {(dados?.isAgendamento && dados.dadosAgendamento?.type === "SINGLE") || !dados?.isAgendamento && (
          <div>
          <div className={styles.containerTituloSecao}>
            <span>
              {dados.isAgendamento && dados.dadosAgendamento?.type === "SINGLE" ? 'Agendado para:' : 'Data do pagamento:'}
            </span>
          </div>
          <div>
            {Intl.DateTimeFormat('pt-BR', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }).format(new Date(dados.dataTransacao))}
          </div>
        </div>
        )}
        {
          (dados?.isAgendamento && dados.dadosAgendamento?.type !== "SINGLE") &&
          agendamentoRecorrenteElement(dados)
        }
        {
          dados?.isTransferenciaInteligente &&
          transferenciaInteligenteElement(dados)
        }
        <div>
          <div className={styles.containerTituloSecao}>
            <span>Fonte do pagamento:</span>
          </div>
          <div>
            Conta Corrente {conta?.value}
            <ExchangeComponent
              exchange="Alterar Fonte"
              onClick={(() => setContaOrigemDefinida(false))}
            />
          </div>
        </div>
        <div>
          <div className={styles.containerTituloSecao}>
            <span>Forma de pagamento:</span>
          </div>
          <div>{dados.tipoTransacao.toString()} - Open Finance</div>
        </div>
      </div>
      )
    }
    else
    {
      return(
        <div>
          <div className={styles.rootContainer}>
            <HeaderSofisaComponent />
            <div className={styles.contentContainer}>
              Não foram recebidos recursos válidos para seleção.
              <div className={styles.containerSubmit}>
                <BotaoComponent
                  titulo="Cancelar"
                  estiloBotao={EstiloBotao.cancelamento}
                  onClick={cancelaOperacao}
                />
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  const agendamentoRecorrenteElement = (dados: DadosPagamento): ReactElement => {
    let frequencia = undefined
    let dadosAgendamento = undefined
    let diaRecorrente = undefined

    switch(dados.dadosAgendamento?.type)
    {
      case "DAILY":
        frequencia = "Diária"
        dadosAgendamento = dados.dadosAgendamento.daily
        break;
        case "WEEKLY":
          frequencia = "Semanal"
          dadosAgendamento = dados.dadosAgendamento.weekly
          diaRecorrente = dados.dadosAgendamento.weekly?.dayOfWeek
        break;
        case "MONTLHY":
          frequencia = "Mensal"
          dadosAgendamento = dados.dadosAgendamento.monthly
          diaRecorrente = dados.dadosAgendamento.monthly?.dayOfMonth
        break;
        case "CUSTOM":
          frequencia = "Customizada"
          dadosAgendamento = dados.dadosAgendamento.custom
          break;
        default:
          frequencia = ""
          dadosAgendamento = undefined
          break;
    }

    return(
      <div>
      <div>
        <div className={styles.containerTituloSecao}>
          <span>
            Frequência:
          </span>
        </div>
        <div>
          {frequencia}
        </div>
      </div>
      <div>
      <div className={styles.containerTituloSecao}>
        <span>
          Primeiro pagamento em:
        </span>
      </div>
      <div>
      {Intl.DateTimeFormat('pt-BR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).format(new Date("startDate" in dadosAgendamento! ? dadosAgendamento.startDate : dadosAgendamento!.dates[0]))}
      </div>
      </div>
      <div>
      <div className={styles.containerTituloSecao}>
        <span>
        {dados.dadosAgendamento?.type === "CUSTOM" || dados.dadosAgendamento?.type === "DAILY" ? 'Número de pagamentos:' : 'Data do pagamento:'}
        </span>
      </div>
      <div>
        {(dados.dadosAgendamento?.type === "CUSTOM" || dados.dadosAgendamento?.type === "DAILY") && (dados.dadosAgendamento.custom?.dates.length)}
        {dados.dadosAgendamento?.type === "WEEKLY" && ("Toda " + diaRecorrente?.toString().toLowerCase().replace("_", " "))}
        {dados.dadosAgendamento?.type === "MONTHLY" && ("Todo dia " + diaRecorrente)}
      </div>
      </div>
      <div>
      <div className={styles.containerTituloSecao}>
        <span>
          Data de início:
        </span>
      </div>
      <div>
      {Intl.DateTimeFormat('pt-BR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).format(new Date("startDate" in dadosAgendamento! ? dadosAgendamento.startDate : dadosAgendamento?.dates[0]! ))}
      </div>
      </div>
      <div>
      <div className={styles.containerTituloSecao}>
        <span>
          Encerra em:
        </span>
      </div>
      <div>
      {"scheduleDates" in dadosAgendamento! ? Intl.DateTimeFormat('pt-BR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).format(new Date( dadosAgendamento.scheduleDates[dadosAgendamento.scheduleDates.length-1])): "Indefinido"}
      </div>
      </div>
      <div>
      <div className={styles.containerTituloSecao}>
        <span>
          Nome da recorrência
        </span>
      </div>
      <div>
        Pagamento
      </div>
      </div>
    </div>
    )
  }

  const transferenciaInteligenteElement = (dados: DadosPagamento): ReactElement => {
    console.log(dados)
    return(
      <div>
      <div>
        <div className={styles.containerTituloSecao}>
          <span>Titular:</span>
        </div>
        <div>CPF: {dados.creditores![0].cpfCnpj}</div>
        <div>Nome: {dados.creditores![0].name}</div>
      </div>
      <div>
        <div className={styles.containerTituloSecao}>
          <span>
            Descrição da autorização:
          </span>
        </div>
        <div>
          {dados.informacoesAdicionais}
        </div>
      </div>
      {
        !!dados.dadosTransferenciaInteligente?.sweeping.totalAllowedAmount && (
          <div>
            <div className={styles.containerTituloSecao}>
              <span>
                Valor máximo a ser transacionado:
              </span>
            </div>
            <div>
              {dados.dadosTransferenciaInteligente?.sweeping.totalAllowedAmount}
            </div>
          </div>
        )
      }
      {
        !!dados.dadosTransferenciaInteligente?.sweeping.transactionLimit && (
          <div>
            <div className={styles.containerTituloSecao}>
              <span>
                Limite por transação:
              </span>
            </div>
            <div>
              {dados.dadosTransferenciaInteligente?.sweeping.transactionLimit}
            </div>
          </div>
        )
      }
      {
        !!dados.dadosTransferenciaInteligente?.sweeping.periodicLimits?.day && (
          <div>
            {
              !!dados.dadosTransferenciaInteligente?.sweeping.periodicLimits.day.quantityLimit && (
                <div>
                  <div className={styles.containerTituloSecao}>
                    <span>Quantidade diária:</span>
                  </div>
                  <div>
                    {dados.dadosTransferenciaInteligente?.sweeping.periodicLimits.day.quantityLimit}
                  </div>
                </div>
              )
            }
            {
              !!dados.dadosTransferenciaInteligente?.sweeping.periodicLimits.day.transactionLimit && (
                <div>
                  <div className={styles.containerTituloSecao}>
                    <span>Limite diário:</span>
                  </div>
                  <div>
                    {dados.dadosTransferenciaInteligente?.sweeping.periodicLimits.day.transactionLimit}
                  </div>
                </div>
              )
            }
          </div>
        )
      }
      {
        !!dados.dadosTransferenciaInteligente?.sweeping.periodicLimits?.week && (
          <div>
            {
              !!dados.dadosTransferenciaInteligente?.sweeping.periodicLimits.week.quantityLimit && (
                <div>
                  <div className={styles.containerTituloSecao}>
                    <span>Quantidade semanal:</span>
                  </div>
                  <div>
                    {dados.dadosTransferenciaInteligente?.sweeping.periodicLimits.week.quantityLimit}
                  </div>
                </div>
              )
            }
            {
              !!dados.dadosTransferenciaInteligente?.sweeping.periodicLimits.week.transactionLimit && (
                <div>
                  <div className={styles.containerTituloSecao}>
                    <span>Limite semanal:</span>
                  </div>
                  <div>
                    {dados.dadosTransferenciaInteligente?.sweeping.periodicLimits.week.transactionLimit}
                  </div>
                </div>
              )
            }
          </div>
        )
      }
      {
        !!dados.dadosTransferenciaInteligente?.sweeping.periodicLimits?.month && (
          <div>
            {
              !!dados.dadosTransferenciaInteligente?.sweeping.periodicLimits.month.quantityLimit && (
                <div>
                  <div className={styles.containerTituloSecao}>
                    <span>Quantidade mensal:</span>
                  </div>
                  <div>
                    {dados.dadosTransferenciaInteligente?.sweeping.periodicLimits.month.quantityLimit}
                  </div>
                </div>
              )
            }
            {
              !!dados.dadosTransferenciaInteligente?.sweeping.periodicLimits.month.transactionLimit && (
                <div>
                  <div className={styles.containerTituloSecao}>
                    <span>Limite mensal:</span>
                  </div>
                  <div>
                    {dados.dadosTransferenciaInteligente?.sweeping.periodicLimits.month.transactionLimit}
                  </div>
                </div>
              )
            }
          </div>
        )
      }
      {
        !!dados.dadosTransferenciaInteligente?.sweeping.periodicLimits?.year && (
          <div>
            {
              !!dados.dadosTransferenciaInteligente?.sweeping.periodicLimits.year.quantityLimit && (
                <div>
                  <div className={styles.containerTituloSecao}>
                    <span>Quantidade anual:</span>
                  </div>
                  <div>
                    {dados.dadosTransferenciaInteligente?.sweeping.periodicLimits.year.quantityLimit}
                  </div>
                </div>
              )
            }
            {
              !!dados.dadosTransferenciaInteligente?.sweeping.periodicLimits.year.transactionLimit && (
                <div>
                  <div className={styles.containerTituloSecao}>
                    <span>Limite anual:</span>
                  </div>
                  <div>
                    {dados.dadosTransferenciaInteligente?.sweeping.periodicLimits.year.transactionLimit}
                  </div>
                </div>
              )
            }
          </div>
        )
      }
      <div>
      <div className={styles.containerTituloSecao}>
        <span>
          Data de início:
        </span>
      </div>
      <div>
      {!!dados.inicioTransacao ? (Intl.DateTimeFormat('pt-BR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).format(new Date(dados.inicioTransacao))) : "Indeterminada"}
      </div>
      </div>
      <div>
      <div className={styles.containerTituloSecao}>
        <span>
          Prazo da autorização:
        </span>
      </div>
      <div>
      { !!dados.expiracaoTransacao ? (Intl.DateTimeFormat('pt-BR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).format(new Date(dados.expiracaoTransacao))) : "Indeterminado"
      }
      </div>
      </div>
      <div>
      <div className={styles.containerTituloSecao}>
        <span>
        Solicitante
        </span>
      </div>
      <div>
        Nome: {dados.nomeSolicitante}
        CPF: {dados.cpf}
      </div>
      </div>
      <div>
      <div className={styles.containerTituloSecao}>
        <span>
          Iniciador das transferências
        </span>
      </div>
      <div>
        {dados.nomeInstituicao}
      </div>
      </div>
      <div>
      <div className={styles.containerTituloSecao}>
        <span>
          ID da autorização
        </span>
      </div>
      <div>
        {dados.idConsentimento.split(":")[2]}
      </div>
      </div>
    </div>
    )
  }

  const getMensagemAviso = (): string | undefined => {
    if (dadosOperacao?.isAgendamento === true)
      return avisoAgendamento;
    else if(contaOrigem !== undefined && contaOrigem?.isMultiplaAlcada === true)
      return avisoMultiplaAlcada.replace("13/07/2021 às 23:59", Intl.DateTimeFormat('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }).format(new Date(dadosOperacao!.expiracaoTransacao ? dadosOperacao!.expiracaoTransacao : "")));
    else if(dadosOperacao?.isTransferenciaInteligente === true)
      return avisoTransferenciaInteligente;
    return;
  };

  
  const avisoElement = (): ReactElement | undefined => {
    const mensagemAviso = getMensagemAviso();
    if (!mensagemAviso) {
      return;
    }

    return (
      <div className={styles.containerAviso}>
        <img src={linhaAzul} className={styles.linhaAzul} />
        <div className={styles.containerMensagem}>
          <div className={styles.image}>
            <img src={infoImage} />
          </div>
          <div className={styles.mensagem}>{mensagemAviso}</div>
        </div>
      </div>
    );
  };

  const limiteTempoElement = (): ReactElement | undefined => {
    return (
      <div className={styles.containerTempoLimite}>
        <img src={linhaVermelha} className={styles.linhaAzul} />
        <div className={styles.containerTempoLimiteMensagem}>
          <div className={styles.image}>
            <img src={warning} />
          </div>
          <div className={styles.mensagem}>Você tem o total de 5 minutos para confirmar essa transação.</div>
        </div>
      </div>
    );
  };

  if (!jaCarregouDados) {
    return <></>;
  }

  if (!contaOrigemDefinida) {
    return (
      <div className={styles.rootContainer}>
        <HeaderSofisaComponent />
        <div className={styles.contentContainer}>
          <LevelComponent level={'Voltar'} />

          {contaOrigemElement()}

          <div className={styles.containerSubmit}>
            <BotaoComponent
              titulo="Confirmar"
              estiloBotao={EstiloBotao.confirmacao}
              onClick={confirmaContaOrigem}
            />
            <BotaoComponent
              titulo="Cancelar"
              estiloBotao={EstiloBotao.cancelamento}
              onClick={cancelaOperacao}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.rootContainer}>
      <HeaderSofisaComponent />
      <div className={styles.contentContainer}>
        <LevelComponent level={'Nova transação'} />

        {consentimentoElement()}
        {avisoElement()}
        {dadosOperacao?.isAgendamento && limiteTempoElement()}

        { recursosValidos && (
        <div className={styles.containerSubmit}>
          <BotaoComponent
            titulo="Confirmar"
            estiloBotao={EstiloBotao.confirmacao}
            onClick={confirmaOperacao}
          />
          <BotaoComponent
            titulo="Cancelar"
            estiloBotao={EstiloBotao.cancelamento}
            onClick={() => setAvisoCancelamento(true)}
          />
          <Modal 
            titulo="Cancelar o pagamento?"
            textoConfirmar="Cancelar pagamento"
            textoCancelar="Voltar"
            descricao={"O pagamento não será concluído e você será direcionado à instituição na qual iniciou o transação."}
            estaHabilitado={avisoCancelamento}
            onConfirm={cancelaOperacao}
            onCancel={() => setAvisoCancelamento(false)}
            />
        </div>
        )

        }
      </div>
    </div>
  );
}
