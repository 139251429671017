import { ReactElement } from 'react';

import { exchangeArrows } from '../assets';
import styles from './exchange.module.css';

export type exchangeProps = {
  exchange: string;
  onClick: () => void;
};

export default function ExchangeComponent({
  exchange: name,
  onClick,
}: exchangeProps): ReactElement {
  return (
    <div className={styles.exchangeContainer}>
      <button
      onClick={onClick}
      >
      <img src={exchangeArrows} />
      <span>{name}</span>
      </button>
    </div>
  );
}
