import  React, { ReactElement, useState, useEffect, ReactNode } from "react";
import style from './modal.module.css'

export type ModalProps = {
  titulo :string, 
  textoConfirmar:string,
  textoCancelar?:string,
  descricao:string | ReactNode,
  descricaoSpan?:string,
  estaHabilitado:boolean,
  onConfirm: () => void,
  onCancel?: () => void
};

export default function Modal({
  titulo, 
  textoConfirmar,
  textoCancelar,
  descricao,
  descricaoSpan,
  estaHabilitado,
  onConfirm,
  onCancel
  }: ModalProps ): ReactElement {
  const [modal, setModal] = useState(false);

  const toggleModal = (clickEffect: () => void) => {
    clickEffect()
    setModal(!modal);
  };

  if(modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }
  useEffect(() => {
    setModal(estaHabilitado)
  });

  return (
    <>
      {modal && (
        <div className={style.modal}>
          <div className={style.overlay}></div>
          <div className={style.modal_content}>
            <div className={style.title}>{titulo}</div>
            <p className={style.description}>
              {descricao}
            </p>
            {descricaoSpan && <span className={style.descriptionSpan}>{descricaoSpan}</span>}
            <div className={style.btn_container}>

              <button className={style.btn_modal_confirm} onClick={() => toggleModal(onConfirm)}>
                {textoConfirmar}
              </button>
              {onCancel && textoCancelar &&
              <button className={style.btn_modal_cancel} onClick={() => toggleModal(onCancel)}>
                {textoCancelar}
              </button>
              }
            </div>
          </div>
        </div>
      )}
    </>
  );
}