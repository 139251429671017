import { ReactElement, useEffect, useState, Fragment } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { useNavigate } from 'react-router-dom';
import {
  checkbox,
  checkmark,
  chevron,
  infoImage,
  levelIcon,
  linhaAzul,
  logoOpenFinance,
  redirecionamento,

} from '../../assets';
import {
  BotaoComponent,
  EstiloBotao,
  EtapasComponent,
  HeaderSofisaComponent,
  Modal
} from '../../components';
import { DadosCompartilhamento, Erro } from '../../models/open-finance';
import { ConsentimentoOpenFinance } from '../../services';
import { OpenFinanceStore } from '../../store';
import styles from './consentimento-dados.module.css';
import { RedirectItpScreenProps } from './redirect-itp.screen';
import { ValidacaoTokenScreenProps } from './validacao-token.screen';

type CategoriaCompartilhavel = {
  titulo: string;
  recursos: {
    titulo: string;
    selecionaveis: RecursoSelecionavel[];
  };
  dados: DadosRecurso
}[];

type DadosRecurso = {
  titulo: string;
  descricao: string;
}[]

type RecursoSelecionavel = {
  id: string;
  titulo: string;
  descricao?: string;
  tipo?: string;
  isSelecionado: boolean;
};

type FormatacaoCamposRecurso = {
  nomeCampo: string;
  nomeFront: string;
}[];

export function ConsentimentoDadosScreen() {
  const navigate = useNavigate();
  const [dadosOperacao, setDadosOperacao] = useState<DadosCompartilhamento>();
  const [categoriasCompartilhaveis, setCategoriasCompartilhaveis] =
    useState<CategoriaCompartilhavel>([]);
  const [passoAtual, setPassoAtual] = useState(0);
  const [categoriasNaoSelecionadas, setCategoriasNaoSelecionadas] = useState<string>("");
  const [jaCarregouDados, setJaCarregouDados] = useState<boolean>(false);
  const [avisoCancelamento, setAvisoCancelamento] = useState<boolean>(false);
  const [avisoDadosNaoSelecionados, setAvisoDadosNaoSelecionados] = useState<boolean>(false);

  const dadosCadastraisPJ = {
    identificacao: [
      'Nome',
      'PEP',
      'Endereço',
      'CPF',
      'Passaporte',
      'Telefone',
      'E-mail',
      'Identidade',
      'Nome do Pai',
      'Nome da Mãe',
      'Data de nascimento',
      'Estado civil',
      'Sexo',
      'Nacionalidade',
    ],
  };
  const dadosCadastraisPF = {
    identificacao: [
      'Nome completo e nome social',
      'Endereço completo',
      'CPF',
      'Passaporte',
      'Telefone',
      'E-mail',
      'Documento de identificação',
      'Filiação',
      'Data de nascimento',
      'Estado civil',
      'Sexo',
      'Nacionalidade',
      'Residência brasileira',
      'Documento estrangeiro',
    ],
    complementares: [
      'Renda',
      'Profissão',
      'Patrimônio',
      'Informações de cônjuge',
      'Produtos contratados',
      'Representantes',
    ],
  };
  const permissionTypes =
  {
    'FINANCING': ["Financiamentos", "Pagamentos de financiamentos", "Parcelas de financiamentos", "Garantias de financiamentos"],
    'LOAN': ["Empréstimos", "Pagamentos de empréstimos", "Parcelas de empréstimos", "Garantias de empréstimos"],
    'UNARRANGED_ACCOUNT_OVERDRAFT': ["Adiantamento a depositantes", "Pagamentos de adiantamento a depositantes", "Parcelas de adiantamento a depositantes", "Garantias de adiantamento a depositantes"],
    'INVOICE_FINANCING': ["Direitos creditórios descontados", "Pagamentos de direitos creditórios descontados", "Parcelas de direitos creditórios descontados", "Garantias de direitos creditórios descontados"],
    'ACCOUNT': ["Saldo", "Extratos de conta", "Limites de conta"],
    'CREDIT_CARD_ACCOUNT': ["Faturas de cartão de crédito", "Limites de cartão de crédito", "Transações de cartão de crédito"],
    'BANK_FIXED_INCOME': ["Renda Fixa Bancária"],
    'CREDIT_FIXED_INCOME': ["Renda Fixa Crédito"],
    'VARIABLE_INCOME': ["Renda Variável"],
    'TREASURE_TITLE': ["Título do Tesouro Direto"],
    'FUND': ["Fundo de Investimento"],
    'EXCHANGE': ["Câmbio"]
  }

  useEffect(() => {
    OpenFinanceStore.getDadosOperacao().then((dadosOperacao) => {
      setDadosOperacao(dadosOperacao as DadosCompartilhamento);
    });
  }, []);

  useEffect(() => {
    if(jaCarregouDados)
    {
      var dadosSelecionaveis = [] as CategoriaCompartilhavel;

      for(let productIndex = 0; productIndex < dadosOperacao!.produtosCliente!.length; productIndex++)
      {
        var tituloCategoria = "";
        var descricaoRecurso = "";
        var tituloDados = "";
        var recurso = {} as RecursoSelecionavel;
        var dados = {} as DadosRecurso;
        var formatacaoCampos: FormatacaoCamposRecurso

        switch(dadosOperacao!.produtosCliente![productIndex].type)
        {        
          case "ACCOUNT": 
            tituloCategoria = "Dados da Conta"
            tituloDados = "Contas"
            formatacaoCampos = [{nomeCampo: "account", nomeFront: "Conta Corrente"}, {nomeCampo: "branch", nomeFront: "Agência"}, {nomeCampo: "branchDigit", nomeFront: ""} ]
            break;
          case "CREDIT_CARD_ACCOUNT": 
            tituloCategoria = "Dados do Cartão de Crédito"
            tituloDados = "Cartões"
            formatacaoCampos = [{nomeCampo: "Name", nomeFront: "Cartão"}, {nomeCampo: "Last4Digits", nomeFront: "Final"}]
            break;
          case "FINANCING":
          case "LOAN": 
          case "UNARRANGED_ACCOUNT_OVERDRAFT": 
          case "INVOICE_FINANCING": 
            tituloCategoria = "Dados da Operação de Crédito"
            tituloDados = "Contratos"
            formatacaoCampos = [{nomeCampo: "productType", nomeFront: "Conta Corrente"}, {nomeCampo: "productSubType", nomeFront: "Final"}]
            break;
          case "BANK_FIXED_INCOME": 
            tituloCategoria = "Dados da Renda Fixa Bancária"
            tituloDados = "Contratos"
            formatacaoCampos = [{nomeCampo: "", nomeFront: "" }]
            break;
          case "CREDIT_FIXED_INCOME": 
            tituloCategoria = "Dados da Renda Fixa Crédito"
            tituloDados = "Contratos"
            formatacaoCampos = [{nomeCampo: "", nomeFront: "" }]
            break;
          case "VARIABLE_INCOME": 
            tituloCategoria = "Dados da Renda Variável"
            tituloDados = "Contratos"
            formatacaoCampos = [{nomeCampo: "", nomeFront: "" }]
            break;
          case "TREASURE_TITLE": 
            tituloCategoria = "Dados do Tesouro de Título Direto"
            tituloDados = "Contratos"
            formatacaoCampos = [{nomeCampo: "", nomeFront: "" }]
            break;
          case "FUND": 
            tituloCategoria = "Dados do Fundo de Investimento"
            tituloDados = "Contratos"
            formatacaoCampos = [{nomeCampo: "investmentId", nomeFront: "Investimento" }]
            break;
          case "EXCHANGE": 
            tituloCategoria = "Dados do Câmbio"
            tituloDados = "Contratos"
            formatacaoCampos = [{nomeCampo: "", nomeFront: "" }]
            break;
          default:
            tituloCategoria = "Dados a serem compartilhados"
            tituloDados = "Contratos"
            formatacaoCampos = [{nomeCampo: "", nomeFront: "" }]
            break;
        }

        try {
          if(dadosOperacao!.produtosCliente![productIndex]!.hasOwnProperty('resources') && dadosOperacao!.produtosCliente![productIndex]!.resources !== undefined && 
            dadosOperacao!.produtosCliente![productIndex]!.resources !== null) {
              descricaoRecurso = montaDescricaoRecurso(productIndex, formatacaoCampos)
          }
        } catch (error) {
          console.error('Erro ao listar produtos.');
          console.log(`Logando produtos do cliente, índice ${productIndex}: ${JSON.stringify(dadosOperacao!.produtosCliente![productIndex]!, null)}`);
        }
        
        if(dadosOperacao && dadosOperacao.permissoes && dadosOperacao.produtosCliente)
        {
          dados = dadosOperacao!.permissoes!.filter((permissao) => {
            let index = dadosOperacao?.produtosCliente?.[productIndex]?.type as keyof typeof permissionTypes
            return Object.values(permissionTypes[index]).includes(permissao.titulo)
          }) as DadosRecurso;
        }
        
        if(dadosOperacao && dadosOperacao.produtosCliente)
        {
          recurso = {
            id: dadosOperacao!.produtosCliente![productIndex].id,
            titulo: tituloDados,
            descricao: descricaoRecurso,
            tipo: dadosOperacao!.produtosCliente![productIndex].type,
            isSelecionado: dadosOperacao!.produtosCliente![productIndex].selecionado
          } as RecursoSelecionavel
        }

        let index = dadosSelecionaveis.findIndex((dado) => dado.titulo == tituloCategoria);
        if(index != -1) dadosSelecionaveis[index].recursos.selecionaveis = [...dadosSelecionaveis[index].recursos.selecionaveis, recurso];
        else dadosSelecionaveis = [...dadosSelecionaveis, ({titulo: tituloCategoria, recursos: {titulo: tituloDados, selecionaveis: [recurso]}, dados: dados})]

      }
    
      setCategoriasCompartilhaveis(dadosSelecionaveis as CategoriaCompartilhavel)
    }
  }, [jaCarregouDados]);

  const montaDescricaoRecurso = (
    productIndex: number,
    formatacaoCampos: FormatacaoCamposRecurso
  ): string => {
    var descricaoRecurso = ""
    var todosRecursos = ""

    for(let fieldIndex = 0; fieldIndex < formatacaoCampos.length; fieldIndex++)
    {
      for(let resourceIndex = 0; resourceIndex < dadosOperacao!.produtosCliente![productIndex]!.resources!.length; resourceIndex++)
      {
        if(dadosOperacao?.produtosCliente?.[productIndex]?.resources?.[resourceIndex]?.key
          && dadosOperacao?.produtosCliente?.[productIndex]?.resources?.[resourceIndex]?.key === formatacaoCampos[fieldIndex].nomeCampo)
        {
          if(formatacaoCampos[fieldIndex].nomeFront !== "")
            descricaoRecurso += formatacaoCampos[fieldIndex].nomeFront + ": "+ dadosOperacao?.produtosCliente?.[productIndex]?.resources?.[resourceIndex]?.value
          else
          {
            descricaoRecurso = descricaoRecurso.substring(0, descricaoRecurso.length - 1)
            descricaoRecurso += " - " + dadosOperacao?.produtosCliente?.[productIndex]?.resources?.[resourceIndex]?.value
          }
          descricaoRecurso += "\\"
          break
        }
      }
    }    
    if(descricaoRecurso !== "")
      return descricaoRecurso

    for(let resourceIndex = 0; resourceIndex < dadosOperacao!.produtosCliente![productIndex]!.resources!.length; resourceIndex++)
    {
      if(dadosOperacao?.produtosCliente?.[productIndex]?.resources?.[resourceIndex]?.key)
      {
        todosRecursos += dadosOperacao?.produtosCliente?.[productIndex]?.resources?.[resourceIndex]?.key + ": "+ dadosOperacao?.produtosCliente?.[productIndex]?.resources?.[resourceIndex]?.value
        todosRecursos += "\\"
      }
    }
    return todosRecursos
  }

  const onClickCheckboxRecurso = (
    indexCategoria: number,
    indexRecurso: number,
  ): void => {
    categoriasCompartilhaveis[indexCategoria].recursos.selecionaveis[
      indexRecurso
    ].isSelecionado =
      !categoriasCompartilhaveis[indexCategoria].recursos.selecionaveis[
        indexRecurso
      ].isSelecionado;
    setCategoriasCompartilhaveis([...categoriasCompartilhaveis]);
  };

  useEffect(() => {
    setJaCarregouDados(!!dadosOperacao);
  }, [dadosOperacao]);

  const cancelaOperacao = () => {
    ConsentimentoOpenFinance.cancela().then((response: Erro | null) => {
      if (!response) {
        navigate('/');
        return;
      }

      navigate('/open-finance/redirect-itp', {
        state: {
          operacaoFoiCancelada: true,
          instituicaoDestino: response.instituicaoDestino,
          redirectLink: response.redirectLink,
        } as RedirectItpScreenProps,
      });
    });
  };

  const getIdsProdutosSelecionados = (): string[] => {
    return categoriasCompartilhaveis?.flatMap((cat) =>
      cat.recursos.selecionaveis
        .filter((r) => r.isSelecionado)
        .map((r) => r.id),
    );
  };

  const checkboxElement = (isChecked: boolean): ReactElement => {
    return isChecked ? (
      <img src={checkbox} />
    ) : (
      <div className={styles.checkboxNaoSelecionado} />
    );
  };

  const estaoTodosOsRecursosSelecionados = (
    categoria: CategoriaCompartilhavel[number],
  ): boolean => {
    return !categoria.recursos.selecionaveis.some((r) => !r.isSelecionado);
  };

  const verificaDadosSelecionados = () => {

    if(dadosOperacao!.produtosCliente!.length > 0)
    {

      let notSelectedNames = ""
      let categoriaNaoSelecionada = false
      
      categoriasCompartilhaveis?.every((categoria) =>{
        categoriaNaoSelecionada = categoria.recursos.selecionaveis.some((recurso) => recurso.isSelecionado == true)
        if(!categoriaNaoSelecionada) notSelectedNames += categoria.titulo + ', '
      })

      if(!categoriaNaoSelecionada)
      {
        notSelectedNames = notSelectedNames.slice(0,-2) + '.'
        setCategoriasNaoSelecionadas(notSelectedNames.toString())
      }

      if(categoriaNaoSelecionada) proximoPasso()
        else  setAvisoDadosNaoSelecionados(true)
    }
    else proximoPasso()

  }

  const proximoPasso = () => {
    if(passoAtual+1 < 3)  setPassoAtual(passoAtual+1);
  };

  const passoAnterior = () => {
    if(passoAtual-1 >= 0)  setPassoAtual(passoAtual-1);
  };

  const renderizaPaginaAtual = () => {

    switch(passoAtual) {
      case 0:
        return confirmacaoElement();  
      case 1:
        return resumoElement();
      case 2:
        return redirecionamentoElement();
      default:
        return confirmacaoElement();
    }
  }

  function dataAtual() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${date}/${month}/${year}`;
  }

  const confirmaOperacao = () => {
    navigate('/open-finance/validacao-token', {
      state: {
        produtosSelecionados: getIdsProdutosSelecionados(),
      } as ValidacaoTokenScreenProps,
    });
  };


  const redirecionamentoElement = (): ReactElement => {
    confirmaOperacao()
    return (
      <div className={styles.containerConsentimento}>
        <div className={styles.containerCompartilhamento}>
          <img className={styles.redirectCompartilhamentoImage} src={redirecionamento}/>
          <div>
            <span className={styles.tituloOperacao}>
              Estamos lhe redirecionando para a instituição {dadosOperacao!.nomeInstituicao}.
            </span>
          </div>
          <div className={styles.containerRedirectFooter}>
            <div className={styles.redirectCompartilhamentoText}>
              Este compartilhamento de dados será realizado através do 
            </div>
            <div>
              <img src={logoOpenFinance}/>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const resumoElement = (): ReactElement => {
    return (
      <div className={styles.containerConsentimento}>
      <BotaoComponent
        titulo="Voltar"
        estiloBotao={EstiloBotao.voltar}
        onClick={passoAnterior}
        imagem={levelIcon}
      />
        <div className={styles.containerResumoDados}>
          <div
            className={styles.containerTituloResumo}
          >
            Resumo do Compartilhamento
          </div>
          <div className={styles.resumoDadosCompartilhados}>
            <div className={styles.resumoTituloDados}>
              Cliente:
            </div>
              <div className={styles.resumoDadosValores}> {dadosOperacao!.customerName} </div>
              <div className={styles.resumoDadosValores}> CPF: {dadosOperacao!.cpf}</div>
            <div className={styles.resumoTituloDados}>
              Data de solicitação:
            </div>
            <div className={styles.resumoDadosValores}>{dataAtual()}</div>
            <div className={styles.resumoTituloDados}>
              Instituição que receberá os dados:
            </div>
            <div className={styles.resumoDadosValores}>{dadosOperacao!.nomeInstituicao}</div>
            <div className={styles.resumoTituloDados}>
              Prazo do compartilhamento:
            </div>
            <div className={styles.resumoDadosValores}>{dataAtual()}</div>
            <Accordion
                allowZeroExpanded
                allowMultipleExpanded
                className={styles.accordion}
              >
            <AccordionItem
                className={styles.accordionItem}
                key={'resumo'}
                >
            <AccordionItemHeading>
              <AccordionItemButton className={styles.accordionButtonResumo}>
                <span>Dados Compartilhados:</span>
                <img src={chevron} />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordionPanel}>
            <div>
              {categoriasCompartilhaveis?.map((categoria, i) => (
                  !categoria.recursos.selecionaveis.some((r) => !r.isSelecionado)?<li key={`categoria-selecionada-${i}`}>{categoria.titulo}</li>:null
              ))}
            </div>
            </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
          </div>
        </div>

        <div className= {styles.avisoResumo}>
          Ao confirmar essa etapa seus dados serão compartilhados e você será redirecionado de volta para a instituição de origem. 
        </div>
        <div className= {styles.avisoResumo}>
          <span>Deseja continuar?</span>
        </div>

      <div className={styles.resumoBotoes}>
        <BotaoComponent
          titulo="Sim, confirmar"
          estiloBotao={EstiloBotao.confirmacao}
          onClick={proximoPasso}
        />
      <BotaoComponent
        titulo="Cancelar"
        estiloBotao={EstiloBotao.cancelamento}
        onClick={() => setAvisoCancelamento(true)}
        />
      </div>
    </div>
    );
  };

  const confirmacaoElement = (): ReactElement => {
    return (
      <div className={styles.containerConsentimento}>
        <div className={styles.containerCompartilhamento}>
          <div>
            <span className={styles.tituloOperacao}>
              Confirmação do compartilhamento de dados
            </span>
          </div>
          {avisoElement()}
          <div className={styles.containerDados}>
            <div
              className={`${styles.containerTituloSecao} ${styles.dadosObrigatorios}`}
            >
              <img src={checkmark} />
              <span>Dados cadastrais</span>
            </div>
            <div className={styles.detalhesDadosCompartilhados}>
              <div className={styles.dadosACompartilhar}>
                Dados a compartilhar:
              </div>
              <Accordion
                allowZeroExpanded
                allowMultipleExpanded
                className={styles.accordion}
              >
                <AccordionItem
                  className={styles.accordionItem}
                  key={'identificação'}
                >
                  <AccordionItemHeading>
                    <AccordionItemButton className={styles.accordionButton}>
                      <span>Identificação</span>
                      <img src={chevron} />
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className={styles.accordionPanel}>
                    <div>
                      {(dadosOperacao!.tipoPessoa === 'PF'
                        ? dadosCadastraisPF!.identificacao
                        : dadosCadastraisPJ!.identificacao
                      ).map((dado, i) => (
                        <li key={`cadastrais-id-${i}`}>{dado}</li>
                      ))}
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                {dadosOperacao!.tipoPessoa === 'PF' && (
                  <AccordionItem
                    className={styles.accordionItem}
                    key={'info-compl'}
                  >
                    <AccordionItemHeading>
                      <AccordionItemButton className={styles.accordionButton}>
                        <span>Informações complementares</span>
                        <img src={chevron} />
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className={styles.accordionPanel}>
                      <div>
                        {dadosCadastraisPF!.complementares!.map((dado, i) => (
                          <li key={`cadastrais-compl-${i}`}>{dado}</li>
                        ))}
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                )}
              </Accordion>
            </div>
          </div>

          {categoriasCompartilhaveis!.map((categoria, indexCat) => (
            <div key={`cat-${indexCat}`} className={styles.containerDados}>
              <div key={`cat-titulo-${indexCat}`} className={`${styles.containerTituloSecao}`}>
                {checkboxElement(estaoTodosOsRecursosSelecionados(categoria))}
                <span key={`cat-titulo-span-${indexCat}`}>{categoria.titulo}</span>
              </div>
              <div key={`cat-detalhes-${indexCat}`} className={styles.detalhesDadosCompartilhados}>
                <div key={`cat-recurso-${indexCat}`} className={styles.recursosACompartilhar}>
                  <span key={`cat-rec-titulo-${indexCat}`}>{categoria.recursos.titulo}</span>
                  <div  key={`cat-rec-selecionaveis-${indexCat}`}>
                    {categoria!.recursos!.selecionaveis!.map((r, indexRec) => (
                      <div key={`cat-rec-${indexCat}-${indexRec}`}>
                      <div
                        key={`cat-rec-value${indexCat}-${indexRec}`}
                        className={styles.recurso}
                      >
                        <div
                          key={`cat-rec-button-${indexCat}-${indexRec}`}
                          onClick={() =>
                            onClickCheckboxRecurso(indexCat, indexRec)
                          }
                        >
                          {checkboxElement(r.isSelecionado)}
                        </div>
                        <div>
                          <span key={`cat-rec-span-${indexCat}-${indexRec}`}className={styles.tituloRecurso}>
                            {r.titulo}
                          </span>
                          {r.descricao && (
                            <span key={`cat-rec-desc-span-${indexCat}-${indexRec}`} className={styles.descricaoRecurso}>
                              {
                                r.descricao.split("\\").map((elemento, indice) => (
                                <Fragment key={indice}>
                                  {elemento}
                                  {indice < r!.descricao!.split("\\").length - 1 && <br />}
                                </Fragment>
                              ))}
                            </span>
                          )}
                        </div>
                      </div>
                      {categoria!.recursos!.selecionaveis.length > 1 &&
                        indexRec <categoria!.recursos!.selecionaveis.length -1 &&             
                        <hr/>
                      }
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.dadosACompartilhar}>
                  Dados a compartilhar:
                </div>
                <Accordion
                  allowZeroExpanded
                  allowMultipleExpanded
                  className={styles.accordion}
                >
                  {categoria.dados!.map((dado) => (
                    <AccordionItem
                      className={styles.accordionItem}
                      key={`cat-acc-${categoria.titulo}-${dado.titulo}`}
                    >
                      <AccordionItemHeading>
                        <AccordionItemButton className={styles.accordionButton}>
                          <span>{dado.titulo}</span>
                          <img src={chevron} />
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className={styles.accordionPanel}>
                        <span>{dado.descricao}</span>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </div>
          ))}
        </div>
      <div className={styles.containerSubmit}>
      <BotaoComponent
        titulo="Confirmar"
        estiloBotao={EstiloBotao.confirmacao}
        onClick={verificaDadosSelecionados}
      />
      <BotaoComponent
        titulo="Cancelar"
        estiloBotao={EstiloBotao.cancelamento}
        onClick={() => setAvisoCancelamento(true)}
        />
    </div>
  </div>
    );
  };

  const avisoElement = (): ReactElement => {
    return (
      <div className={styles.containerAviso}>
        <img src={linhaAzul} className={styles.linhaAzul} />
        <div className={styles.containerMensagem}>
          <div className={styles.image}>
            <img src={infoImage} />
          </div>
          <div className={styles.mensagem}>
            Antes de compartilhar seus dados com a instituição selecionada,
            precisamos confirmar algumas informações:
          </div>
        </div>
      </div>
    );
  };
  
  return (
    <>
      {jaCarregouDados && (
        <div>
          <div className={styles.rootContainer}>
            <HeaderSofisaComponent />
            <div className={styles.contentContainer}>
              <EtapasComponent
                passos={['Confirmação', 'Resumo', 'Redirecionamento']}
                atual={passoAtual}
              />
              {renderizaPaginaAtual()}
              <Modal 
                titulo="Atenção"
                textoConfirmar="Ok, entendi"
                descricao={<>Não há dados selecionados nos blocos de <b>{categoriasNaoSelecionadas}</b></>}
                descricaoSpan="Por favor, selecione no mínimo 1 item"
                estaHabilitado={avisoDadosNaoSelecionados}
                onConfirm={() => setAvisoDadosNaoSelecionados(false)}
                onCancel={ () => {void 0}}
              />
              <Modal 
                titulo="Atenção"
                textoConfirmar="Sim, continuar"
                textoCancelar="Não, mudei de ideia"
                descricao={"Tem certeza que deseja cancelar o compartilhamento?"}
                estaHabilitado={avisoCancelamento}
                onConfirm={cancelaOperacao}
                onCancel={() => setAvisoCancelamento(false)}
              />
            </div>
          </div>

        </div>
      )}
    </>
  );
}
