import { ReactElement } from 'react';
import { Tadpole } from 'react-svg-spinners';

import styles from './loading.module.css';

export type LoadingProps = {
  children?: ReactElement;
  mensagem: string;
};

export default function LoadingComponent({
  children,
  mensagem,
}: LoadingProps): ReactElement {
  return (
    <div className={styles.rootContainer}>
      {children}
      <div className={styles.loadingContainer}>
        <Tadpole color="blue" width={36} height={36} />
        <span>{mensagem}</span>
      </div>
    </div>
  );
}
