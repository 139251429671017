import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function HomeScreen(): JSX.Element {
  const { hash } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (hash) {
      navigate('/open-finance/autorizacao', {
        state: {
          commandId: hash.substring(1),
        },
      });
    }
  }, []);

  return <></>;
}
