import { ReactElement } from 'react';

import { levelIcon } from '../assets';
import styles from './level.module.css';

export type LevelProps = {
  level: string;
};

export default function LevelComponent({
  level: name,
}: LevelProps): ReactElement {
  return (
    <div className={styles.rootContainer}>
      <img src={levelIcon} />
      <span>{name}</span>
    </div>
  );
}
