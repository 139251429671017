import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HeaderSofisaComponent, LoadingComponent } from '../../components';
import { AutenticacaoOpenFinanceService, ConsentimentoOpenFinance } from '../../services';
import { RedirectItpScreenProps } from './redirect-itp.screen';
import { ResultadoAutenticacao } from '../../models/open-finance';

export function ClienteAutenticadoScreen() {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    const id = pathname.split('/').slice(-1)[0];
    AutenticacaoOpenFinanceService.onAutenticacaoExterna(id).then(
      (response) => {
        if (response.sucesso) {
          if (response.tipoOperacao === 'PAGAMENTO') {
            navigate('/open-finance/consentimentoPagamento');
          } else {
            navigate('/open-finance/consentimentoDados');
          }
        } else {
            alertErro(
              'Um erro ocorreu na autenticação. Por favor, tente novamente.', response
            );
        }
      },
    );
  }, []);

  const redirectItp = (response : ResultadoAutenticacao) => {
      navigate('/open-finance/redirect-itp', {
        state: {
          operacaoFoiCancelada: true,
          instituicaoDestino: response.instituicaoDestino,
          redirectLink: response.redirectLink,
        } as RedirectItpScreenProps,
      });
  }
  
  const alertErro = (msg: string, response: ResultadoAutenticacao) => {
    toast.error(msg, {
      position: 'top-center',
      autoClose: 5000,
      onClose: () => redirectItp(response)
    });
  };

  return (
    <>
      <ToastContainer />
      <LoadingComponent mensagem="Carregando...">
        <HeaderSofisaComponent />
      </LoadingComponent>
    </>
  );
}
