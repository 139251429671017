import { HeaderSofisaComponent } from '../../components';
import styles from './autorizacao-abortada.module.css';

export function AutorizacaoAbortadaScreen() {
  return (
    <div className={styles.rootContainer}>
      <HeaderSofisaComponent />
      <div className={styles.containerMensagem}>
        <span className={styles.titulo}>Um erro ocorreu na autorização.</span>
        <span className={styles.subtitulo}>
          Por favor, recomece a operação.
        </span>
      </div>
    </div>
  );
}
