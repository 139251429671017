import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { HeaderSofisaComponent, LoadingComponent } from '../../components';
import {
  AutenticacaoOpenFinanceService,
  AutorizacaoOpenFinanceService,
} from '../../services';

export interface AutorizacaoScreenProps {
  commandId: string;
}

export function AutorizacaoScreen() {
  const { state } = useLocation() as { state: AutorizacaoScreenProps };

  const navigate = useNavigate();

  useEffect(() => {
    AutorizacaoOpenFinanceService.armazenaAutorizacao(state.commandId).then(
      async (response: boolean) => {
        if (
          !response ||
          !(await AutenticacaoOpenFinanceService.redirecionaParaIdpExterno())
        ) {
          navigate('/open-finance/autorizacao-abortada');
        }
      },
    );
  }, []);

  return (
    <LoadingComponent mensagem="Carregando...">
      <HeaderSofisaComponent />
    </LoadingComponent>
  );
}
