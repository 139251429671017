import { ReactElement, useEffect, useState } from 'react';

import { proibido, proibidoEscuro } from '../assets';
import styles from './input.module.css';

export type InputProps = {
  label?: string;
  inlineLabel?: string;
  tamanhoMaximo?: number;
  isTextoSensivel?: boolean;
  modoInput?: 'text' | 'numeric';
  jaSubmeteu?: boolean;
  validacao?: {
    obrigatorio?: boolean;
    regra?: (valor: string) => boolean;
  };
  onChangeValor: (valor: string | undefined) => void;
  onChangeValidade?: (validade: boolean | undefined) => void;
};

export default function InputComponent({
  label,
  inlineLabel,
  tamanhoMaximo,
  isTextoSensivel,
  modoInput,
  jaSubmeteu,
  validacao,
  onChangeValor,
  onChangeValidade,
}: InputProps): ReactElement {
  const [valor, setValor] = useState<string>();
  const [isValido, setIsValido] = useState<boolean | undefined>();
  const [jaFocouNoCampo, setJaFocouNoCampo] = useState<boolean>(false);
  const [isVazio, setIsVazio] = useState<boolean>();

  useEffect(() => {
    validaCampo();
  }, [valor, validacao, jaFocouNoCampo, jaSubmeteu]);

  useEffect(() => {
    if (!validacao) {
      return;
    }

    if (onChangeValidade) {
      onChangeValidade(isValido);
    }
  }, [isValido]);

  useEffect(() => {
    if (!jaFocouNoCampo) {
      return;
    }

    onChangeValor(valor);
  }, [valor, jaFocouNoCampo, onChangeValor]);

  useEffect(() => {
    setIsVazio(!isCampoPreenchido(valor));
  }, [valor]);

  const isCampoPreenchido = (campo: string | null | undefined): boolean => {
    return campo != null && campo.trim() !== '';
  };

  const validaCampo = (): void => {
    if (!validacao || (!jaFocouNoCampo && !jaSubmeteu)) {
      return;
    }

    const { obrigatorio, regra } = validacao;

    setIsValido(
      (!obrigatorio || isCampoPreenchido(valor)) &&
        (regra == null || regra(valor!)),
    );
  };

  return (
    <div
      className={`${styles.labelInputContainer} ${
        isValido === false ? styles.inputInvalido : ''
      }`}
    >
      {label?<label className={styles.label}>{label}</label>:<label className={styles.label}/>}
      <input
        className={styles.input}
        inputMode={modoInput}
        maxLength={tamanhoMaximo}
        type={isTextoSensivel ? 'password' : 'text'}
        placeholder={inlineLabel?inlineLabel:''}
        onChange={(e) => setValor(e.target.value)}
        onBlur={() => setJaFocouNoCampo(true)}
      />
      <div className={styles.mensagemErro}>
        {isValido === false && isVazio && validacao?.obrigatorio && (
          <>
            <img src={proibidoEscuro} />
            <span>Campo obrigatório.</span>
          </>
        )}
      </div>
    </div>
  );
}
